import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { persist, subscribeWithSelector, devtools } from 'zustand/middleware'

import { createSelectors } from '@store/utils'
import { createOnboardingSlice, OnboardingSlice, initialOnboardingState } from './slices/onboarding'
import { createOrganizationSlice, OrganizationSlice, initialOrganizationState } from './slices/organization'

export interface State {}

export interface Actions {}

type Store = State &
  Actions &
  OnboardingSlice &
  OrganizationSlice & {
    reset: () => void
  }

const createState = immer<Store>((...args) => ({
  ...createOnboardingSlice(...args),
  ...createOrganizationSlice(...args),
  reset: () => args[0]({ ...initialOnboardingState, ...initialOrganizationState }),
}))

const persisState = persist(createState, {
  name: 'store',
  getStorage: () => localStorage,
  partialize: (state) => {
    return {
      startPath: state.startPath,
      onboardingStep: state.onboardingStep,
      onboardingType: state.onboardingType,
      organization: state.organization,
      organizationName: state.organizationName,
      integration: state.integration,
    }
  },
})

const name = 'state'
const useStoreBase = create(devtools(subscribeWithSelector(persisState), { name, store: name, enabled: true }))

export const useStore = createSelectors(useStoreBase)
export default useStore
